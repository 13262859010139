@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'NEXON Lv1 Gothic OTF';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv1 Gothic OTF.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NEXONFootballGothicBA1';
  src: url('https://gcore.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXONFootballGothicBA1.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'NEXON Lv1 Gothic OTF';
  max-width: 100%;
}

ol,
ul,
li {
  list-style: none;
}
textarea,
input {
  resize: none;
  outline: none;
  font-size: 16px;
  -webkit-text-size-adjust: 100%;
}

span,
label {
  display: inline-block;
}

.scroll {
  /* 스크롤바 UI 숨기기 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 및 Edge */
}

.scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}
/* CSS 파일에 추가 */
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.gradient-button {
  background: linear-gradient(to right, rgb(85, 53, 242), rgb(111, 46, 233));
}
.circle {
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #00e4a6; /* 원의 색상 */
  border-radius: 50%; /* 원 모양 */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}

.triangle {
  position: absolute;
  bottom: -20px; /* 화살표 위치 조정 */
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 28px solid #00e4a6; /* 화살표 색상 */

  @media (max-width: 768px) {
    bottom: -8px;
    border-top: 14px solid #00e4a6;
  }
}

.number {
  position: relative;
  z-index: 1; /* 숫자가 화살표 위에 오도록 설정 */
}

.landing-highlight {
  /* 밑줄 효과를 위한 스타일 */
  background-image: linear-gradient(120deg, #ffd700 0%, #ffd700 100%);
  background-repeat: no-repeat;
  background-size: 100% 0%;
  background-position: 0 100%;
  transition: background-size 0.2s ease-in;
}
@layer components {
  .modal-bg {
    @apply fixed top-0 left-0 w-screen h-[100dvh] flex flex-col justify-center items-center bg-[rgba(0,0,0,0.8)] z-[999991];
  }
  .landing-highlight {
    @apply bg-yellow2 rounded-full whitespace-pre-wrap;
  }
  .landing-big-text {
    @apply text-xs md:text-xl lg:text-2xl font-bold;
  }
  .text-xs,
  .text-sm,
  .text-base,
  .text-lg,
  .text-xl,
  .text-2xl,
  .text-3xl,
  .text-4xl,
  .text-5xl {
    @apply whitespace-pre-wrap;
  }
}
